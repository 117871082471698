<template>
	<div class="d-flex flex-column flex-root">
		<div
		id="kt_login"
		:class="{
			'login-signin-on': this.state == 'signin',
			'login-forgot-on': this.state == 'forgot',
			'login-forgotnewpassword-on': this.state == 'forgotnewpassword',
		}"
		class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
		>
			<!--begin::Aside-->
			<div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #f2c98a">
				<div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
					<a href="#" class="text-center mb-10">
						<img src="media/logos/logo-genic.png" class="max-h-70px" alt="" />
					</a>
					<h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #986923">
						Discover Amazing Genic Assets <br />with great build tools
					</h3>
				</div>
				<div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
					:style="{ backgroundImage: `url(${backgroundImage})` }"></div>
			</div>
			<!--begin::Aside-->
			<!--begin::Content-->
			<div
				class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
				<div class="d-flex flex-column-fluid flex-center">
					<!--begin::Signin-->
					<div class="login-form login-signin">
						<form class="form" novalidate="novalidate" id="kt_login_signin_form">
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
									Welcome to Genic Assets
								</h3>
							</div>
							<div class="login-error" v-if="error">
								The email or password that you've entered is incorrect.
							</div>
							<div class="form-group">
								<label class="font-size-h6 font-weight-bolder text-dark">Email</label>
								<div id="example-input-group-1" label="" label-for="example-input-1">
									<input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text"
										name="email" ref="email" :disabled="isLoading" v-model="form.email" />
								</div>
							</div>
							<div class="form-group">
								<div class="d-flex justify-content-between mt-n5">
									<label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
									<a class="text-primary link font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer"
										id="kt_login_forgot" link @click="showForm('forgot'),error =false">Forgot Password ?</a>
								</div>
								<div id="example-input-group-2" label="" label-for="example-input-2">
									<input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
										type="password" name="password" ref="password" :disabled="isLoading"
										v-model="form.password" autocomplete="off" v-on:keyup.enter="submitLogin" />
								</div>
							</div>
							<div class="pb-lg-0 pb-5">
								<button :disabled="isLoading" type="button" v-on:click="submitLogin"
									ref="kt_login_signin_submit"
									class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
									Sign In
								</button>
							</div>
						</form>
					</div>
					<!--end::Signin-->
					<!--begin::Forgot-->
					<div class="login-form login-forgot">
						<!--begin::Form-->
						<div class="login-error" v-if="error">
								User Account Not Exists 
							</div>
						<form class="form" novalidate="novalidate" id="kt_login_forgot_form" ref="kt_login_forgot_form">
							<!-- <div class="alert fade alert-danger" role="alert" v-bind:class="{ show: errors.length }" v-if="errors?.length"> 
								<div v-for="(error, i) in errors" :key="i" class="alert-text">
									{{ error }}
								</div>
							</div> -->
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
									Forgotten Password ?
								</h3>
								<p class="text-muted font-weight-bold font-size-h4">
									Enter your email to reset your password
								</p>
							</div>
							<div class="form-group">
								<v-text-field v-model="form.email"></v-text-field>

							</div>
							<div v-if="rentmessage" class="alert alert-success" role="alert">
								<div class="alert-text">
									{{ rentmessage }}
								</div>
							</div>

							<div class="form-group d-flex flex-wrap pb-lg-0">
								<button type="button" :disabled="isLoading" id="kt_login_forgot_submit"
									v-on:click="forgotpassword('forgotnewpassword')"
									class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4">
									Submit
								</button>
								<button type="button" id="kt_login_forgot_cancel"
									class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
									@click="error= false,showForm('signin')">
									Cancel
								</button>
							</div>
						</form>
					</div>
					<!--end::Forgot-->
				</div>
				<!--begin::Content footer-->
				<div class="login-form login-forgotnewpassword">
					<!--begin::Form-->
					<form id="kt_login_forgotnewpassword_form" ref="kt_login_forgotnewpassword_form" class="form"
						novalidate="novalidate">
						<div class="alert alert-danger" v-if="error">
							<div  class="alert-text">
								Something went wrong. Please try again later.
							</div> 
						</div>

						<div v-if="rentmessage" class="alert alert-success" role="alert">
							<div class="alert-text">
								{{ rentmessage }}
							</div>
						</div>

						<div class="form-group">
							<label class="font-size-h6 font-weight-bolder text-dark">New Pasword</label>
							<div id="email-input-group" label="" label-for="email-input">
								<v-text-field v-model="form.password" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="show2 ? 'text' : 'password'" v-on:click:append="show2 = !show2"
									placeholder="New Password"></v-text-field>
							</div>
							<label class="font-size-h6 font-weight-bolder text-dark">Confirm Pasword</label>
							<div id="email-input-group" label="" label-for="email-input">
								<v-text-field v-model="form.conform_password"
									:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" :type="show3 ? 'text' : 'password'"
									v-on:click:append="show3 = !show3" placeholder="Confirm Password"></v-text-field>
							</div>
							<label class="font-size-h6 font-weight-bolder text-dark">OTP</label>
							<div id="email-input-group" label="" label-for="email-input">
								<v-text-field v-model="form.password_otp"></v-text-field>
							</div>
						</div>

						<div class="form-group d-flex flex-wrap pb-lg-0">
							<button id="kt_login_forgot_submit"
								class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" type="button"
								v-on:click="ConformPassword">
								Submit
							</button>
							<button id="kt_login_forgot_cancel"
								class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" type="button"
								@click="rentmessage =''; error = false; showForm('signin')">
								Cancel
							</button>
						</div>
					</form>
				</div>
				<div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
					<a href="#" class="text-primary font-weight-bolder font-size-h5">Terms</a>
					<a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Plans</a>
					<a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Contact Us</a>
				</div>
				<!--end::Content footer-->
			</div>
			<!--end::Content-->
		</div>
	</div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";

export default {
	name: "login-1",
	data() {
		return {
			state: "signin",
			isLoading: false,
			error: false,
			ErrorMsg:"",
			show2: false,
			confirmErr:"",
			show3: false,
			rentmessage: "",
			form: {
				email: process.env.NODE_ENV === "development" ? "admin@bthrust.com" : null,
				password: process.env.NODE_ENV === "development" ? "Admin@123" : null,
				password_otp: null,
				conform_password: null,
			},
		};
	},
	computed: {
		
		...mapState({
			errors: (state) => state.auth.errors,
            message: (state) => state.auth.message,
		}),
		backgroundImage() {
			return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
		},
	},
	methods: {
		forgotpassword(form) {
			const _this = this;
			_this.isLoading = true;
			_this.error = false;
			const payload = {
				user_email: _this.form.email
			}

		    	ApiService.put('forgotnew-password', payload)
				.then((output) => {
					_this.rentmessage = output.message;
					if (form) {
						this.state = form;
						var form_name = "kt_login_" + form + "_form";
						KTUtil.animateClass(
							KTUtil.getById(form_name),
							"animate__animated animate__backInUp"
						);
					}
				})
				.catch(() => {
					_this.error = true;
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		ConformPassword() {
			const _this = this;
			_this.isLoading = true;
			_this.error = false;
			const payload = {
				password_otp: _this.form.password_otp,
				password: _this.form.password,
				conform_password: _this.form.conform_password,
			}
			ApiService.put('confirm-password', payload)
				.then((output) => {
					_this.rentmessage = output.message;
					_this.showForm("signin");
				})
				.catch(() => {
					_this.error = true;
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		submitLogin() {
			const _this = this;

			_this.$store.dispatch(LOGOUT);

			const submitButton = _this.$refs["kt_login_signin_submit"];

			submitButton.classList.add("spinner", "spinner-light", "spinner-right");

			_this.isLoading = true;
            _this.error  = false,
			_this.$store
				.dispatch(LOGIN, _this.form)
				.then(() => {
					
					const resolved = _this.$router.resolve({ name: "dashboard" });
					window.location.href = resolved.href;
					this.error  = false
				})
				.catch(() => {
					_this.error  = true,
					_this.isLoading = false;
					submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
				});
		},
		showForm(form) {
			this.state = form;
			var form_name = "kt_login_" + form + "_form";
			KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
		},
	},
};
</script>
